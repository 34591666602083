<template>
    <div class="bg-secondary p-10 flex flex-col sm:flex-row items-start justify-center max-w-5xl my-10 mx-4 sm:mx-auto rounded-lg gap-8"
        id="qualifications">

        <img src="../../src/assets/my-face.jpg" alt="Picture of my face" title="Picture of my face"
            class="w-32 sm:w-40 rounded-lg border-2">

        <div class=" text-white">
            <h1 class="uppercase md:text-4xl text-2xl">Dyslexia and Dyscalculia Services & Assessments for Children and
                Young adults</h1>

            <p class="font-extralight mt-4 text-sm sm:text-base">I have been a qualified primary school teacher for over
                20
                years. During this time, I have tutored one-to-one, been a <strong class="italic">SATs marker</strong>
                and have <strong class="italic">lead English</strong> in a large
                primary school. I am a <strong class="italic">qualified specialist</strong> teacher and <strong
                    class="italic">assessor</strong> of <strong class="italic">dyslexia and dyscalculia</strong>. I am
                currently one of the <strong class="italic">Advisory Teachers</strong> for the <strong
                    class="italic">Devon SpLD Service</strong>.</p>
            <p class="font-extralight mt-4 text-sm sm:text-base">I am fully qualified: <strong class="italic">B.Ed, PG
                    Cert
                    (SpLD - dyslexia), level 5 dyscalculia, APC,
                    AMBDA and DBS checked</strong>. I
                am available for <strong class="italic">informal assessments</strong> and <strong class="italic">full
                    diagnostic assessments</strong> if required. I have a
                son who was diagnosed as dyslexic and then later on with dyscalculia. Understanding his difficulties and
                being able to support him became very important to me and this motivated me to want to develop my skills
                further in order to help him and others.</p>
            <p class="font-extralight mt-4 text-sm sm:text-base">If you require any of my services, whether this be
                Dyslexia
                and Dyscalculia
                support or assessments, please <strong class="italic">drop me an email</strong> explaining your
                requirements. I will
                aim to get started as soon as
                possible.</p>

            <router-link to="/contact" class="primary-button">Get in touch</router-link>
        </div>
    </div>
</template>